import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Navigate, useRoutes } from "react-router-dom";
import { indexRouter } from "./app/routers/indexRouter";

function App() {
  let router = useRoutes([
    { path: "/", element: <Navigate to="/trang-chu" replace /> },
    indexRouter,
  ]);

  return <div>{router}</div>;
}

export default App;
