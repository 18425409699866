import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import Marquee from "react-fast-marquee";
import ObservationSVG from "../../../assets/svg/observation.svg";
import ProtectionSVG from "../../../assets/svg/protection.svg";
import RegisterSVG from "../../../assets/img/register.png";

export default function HomePage() {
  return (
    <>
      <div className="section-spacing-lg bg-gradient-2 bg-opacity-10">
        <div className="container pt-4">
          <div className="row g-3 g-lg-4 g-xl-5">
            <div className="col-12 col-lg-6">
              <h2 className="display-5 fw-medium mb-0 uppercase">
                <span
                  className="text-gradient-2 fw-bold display-5"
                  style={{ lineHeight: "1.6" }}
                >
                  Hệ thống bảo hộ
                </span>
                <span className="d-block fs-4 text-start text-black-60">
                  Dữ Liệu Khoa Học - Công Nghệ Quốc Gia
                </span>
              </h2>
            </div>
            <div className="col-12 col-lg-6">
              <p className="text-dark-70">
                Hệ thống bảo vệ quyền sở hữu trí tuệ cho sáng chế, dữ liệu khoa
                học công nghệ, đảm bảo tính hợp pháp và an toàn trong quá trình
                đăng ký, giám sát, và thương mại hóa.
              </p>
              <a className="button-fancy-gradient-2 mt-4" href="#">
                Xem thêm
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container z-index-1">
        <div className="n-margin-4">
          <div className="row g-3">
            <div className="col-12 col-lg-4">
              <div
                className="interactive-box border-radius-1 box-shadow mt-lg-3"
                style={{ backgroundColor: "#e9f7fd" }}
              >
                <img src={RegisterSVG} alt="" />
                <div className="interactive-overlay">
                  <div className="interactive-inner p-4 p-lg-5">
                    <h6 className="uppercase mb-0">
                      Đăng ký
                      <br /> quyền sở hữu trí tuệ
                    </h6>
                  </div>
                  <div className="interactive-hover p-4 p-lg-5">
                    <p>
                      Đăng ký ngay quyền sở hữu trí tuệ để các sáng chế giải
                      pháp công nghệ của bạn được bảo vệ và ngăn chặn sao chép,
                      sử dụng trái phép kịp thời.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div
                className="interactive-box border-radius-1 box-shadow"
                style={{ backgroundColor: "#e9f7fd" }}
              >
                <img src={ObservationSVG} alt="" />

                <div className="interactive-overlay">
                  <div className="interactive-inner p-4 p-lg-5">
                    <h6 className="uppercase mb-0">
                      Phát hiện vi phạm <br />
                      quyền sở hữu trí tuệ
                    </h6>
                  </div>
                  <div className="interactive-hover p-4 p-lg-5">
                    <p>
                      Sử dụng AI để phát hiện và ngăn chặn vi phạm quyền sở hữu
                      trí tuệ KTCN một cách nhanh chóng, bảo vệ tối đa giá trị
                      sáng tạo của bạn!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div
                className="interactive-box border-radius-1 box-shadow mt-lg-3"
                style={{ backgroundColor: "#e9f7fd" }}
              >
                <img src={ProtectionSVG} alt="" />
                <div className="interactive-overlay">
                  <div className="interactive-inner p-4 p-lg-5">
                    <h6 className="uppercase mb-0">
                      Bảo hộ <br />
                      quyền sở hữu trí tuệ
                    </h6>
                  </div>
                  <div className="interactive-hover p-4 p-lg-5">
                    <p>
                      Bảo hộ quyền sở hữu trí tuệ của bạn một cách nhanh chóng
                      với các quy định & chính sách hiện hành được cập nhật liên
                      tục!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-spacing">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-12 col-lg-4">
              <div className="circle-text">
                <svg className="circle-svg rotating" viewBox="0 0 200 200">
                  <path
                    id="textPath"
                    d="M 85,0 A 85,85 0 0 1 -85,0 A 85,85 0 0 1 85,0"
                    transform="translate(100,100)"
                    fill="none"
                    stroke="rgba(28, 30, 32, 0.1)"
                    stroke-width="10"
                  ></path>
                  <text text-anchor="start">
                    <textPath xlinkHref="#textPath" textLength="260%">
                      Hệ thống quản lý dữ liệu khoa học - công nghệ quốc gia •
                    </textPath>
                  </text>
                </svg>
                <div className="circle-text-inner icon-4xl text-gradient-2">
                  <i className="ti-crown"></i>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <h2 className="display-7 fw-normal">
                Đăng ký quyền sở hữu trí tuệ ngay hôm nay để bảo vệ ý tưởng của
                bạn và đảm bảo giá trị sáng tạo được công nhận và an toàn!
              </h2>
              <ul className="list-inline-2xl mt-4 mt-lg-5">
                <li>
                  <a
                    className="button button-lg button-rounded button-gradient-2 button-hover-slide"
                    href="pages-marketing/about.html"
                  >
                    <span data-text="Đăng ký ngay">Đăng ký ngay</span>
                  </a>
                </li>
                <li>
                  <a
                    className="link-hover-line font-family-secondary"
                    href="pages-marketing/contact.html"
                  >
                    Tìm hiểu thêm
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section-spacing-sm bg-dark">
        <div className="container">
          <div className="row g-4 g-lg-5">
            <div className="col-12 col-lg-4">
              <h2 className="font-family-tertiary">
                Chia sẻ của các tổ chức, cá nhân đã tham gia.
              </h2>
              <ul className="list-inline mt-3">
                <li>
                  <a
                    className="button-circle button-circle-white-2 button-circle-hover-slide testimonial-slider-2-prev"
                    href="#"
                  >
                    <i className="bi bi-arrow-left"></i>
                    <i className="bi bi-arrow-left"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="button-circle button-circle-white-2 button-circle-hover-slide testimonial-slider-2-next"
                    href="#"
                  >
                    <i className="bi bi-arrow-right"></i>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-8">
              <Swiper
                modules={[Pagination]}
                spaceBetween={40} // Khoảng cách giữa các slide
                // slidesPerView={3} // Số slide hiển thị cùng lúc
                breakpoints={{
                  0: {
                    // width: 576,
                    slidesPerView: 1,
                  },
                  576: {
                    // width: 576,
                    slidesPerView: 2,
                  },
                  768: {
                    // width: 768,
                    slidesPerView: 3,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="testimonial-quote-box">
                    <div className="quote">
                      <p>
                        Quy trình rất chuyên nghiệp, đăng ký dễ dàng. Bảo vệ
                        hiệu quả!
                      </p>
                    </div>
                    <div className="quote-author">
                      <img
                        src={require("../../../assets/images/testimonial-1.jpg")}
                        alt=""
                        data-rjs="2"
                      />
                      <div className="ps-3">
                        <h6 className="sm-heading mb-0">Christina Gray</h6>
                        <span>CMO @Company</span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-quote-box">
                    <div className="quote">
                      <p>
                        Phát hiện vi phạm bản quyền rất nhanh chóng. Hệ thống
                        rất tiên tiến!
                      </p>
                    </div>
                    <div className="quote-author">
                      <img
                        src={require("../../../assets/images/testimonial-2.jpg")}
                        alt=""
                        data-rjs="2"
                      />
                      <div className="ps-3">
                        <h6 className="sm-heading mb-0">Alexander Warren</h6>
                        <span>CEO @Company</span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial-quote-box">
                    <div className="quote">
                      <p>Hệ thống rất tuyệt vời!</p>
                    </div>
                    <div className="quote-author">
                      <img
                        src={require("../../../assets/images/testimonial-3.jpg")}
                        alt=""
                        data-rjs="2"
                      />
                      <div className="ps-3">
                        <h6 className="sm-heading mb-0">Barbara Myers</h6>
                        <span>Creative Director</span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>

          <div className="swiper clients-slider-6 mt-5">
            <Swiper
              modules={[Autoplay]}
              spaceBetween={50} // Khoảng cách giữa các slide
              slidesPerView={6} // Số slide hiển thị cùng lúc
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide>
                <img
                  src={require("../../../assets/images/client-1-dark.png")}
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("../../../assets/images/client-2-dark.png")}
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("../../../assets/images/client-3-dark.png")}
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("../../../assets/images/client-4-dark.png")}
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("../../../assets/images/client-5-dark.png")}
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("../../../assets/images/client-6-dark.png")}
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("../../../assets/images/client-1-dark.png")}
                  alt=""
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="section-spacing">
        <div className="container">
          <div className="row align-items-center custom-row">
            <div className="col-12 col-lg-6 custom-col">
              <div className="row g-3">
                <div className="col-6 text-end">
                  <div
                    data-bottom-top="transform: translateY(10px)"
                    data-top-bottom="transform: translateY(-36px)"
                  >
                    <img
                      className="border-radius-1"
                      src={require("../../../assets/images/marketing-about-1.jpg")}
                      alt=""
                    />
                    <div className="d-flex align-items-center mt-1">
                      <p>
                        Bằng sáng chế <br /> đã đăng ký
                      </p>
                      <div className="h2 display-3 fw-normal ps-3">
                        <span className="text-gradient-2">300+</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-5">
                  <div
                    data-bottom-top="transform: translateY(-10px)"
                    data-top-bottom="transform: translateY(40px)"
                  >
                    <img
                      className="border-radius-1"
                      src={require("../../../assets/images/marketing-about-2.jpg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 mt-lg-5">
              <Swiper
                modules={[Pagination]}
                spaceBetween={40} // Khoảng cách giữa các slide
                // slidesPerView={3} // Số slide hiển thị cùng lúc
                breakpoints={{
                  0: {
                    // width: 576,
                    slidesPerView: 1,
                  },
                  576: {
                    // width: 576,
                    slidesPerView: 2,
                  },
                  768: {
                    // width: 768,
                    slidesPerView: 3,
                  },
                }}
              >
                <span data-text="Đọc thêm">Đọc thêm</span>
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gradient-2 bg-opacity-10 py-3">
        <Marquee speed={100}>
          <div className="marquee-container">
            <div className="marquee">
              <h1 className="display-2 font-family-tertiary fw-normal fst-italic text-dark-20 mb-0">
                Quyền Sở Hữu Trí Tuệ
              </h1>
              <h1 className="display-2 font-family-tertiary fw-normal fst-italic text-dark-20 mb-0">
                Sáng chế{" "}
              </h1>
              <h1 className="display-2 font-family-tertiary fw-normal fst-italic text-dark-20 mb-0">
                Giải pháp
              </h1>
              <h1 className="display-2 font-family-tertiary fw-normal fst-italic text-dark-20 mb-0">
                Kỹ Thuật - Công Nghệ
              </h1>
            </div>
          </div>
        </Marquee>
      </div>

      <div className="section-spacing pt-5  bg-gradient-2">
        <div className="container">
          <div className="row g-4 g-xl-5 align-items-center">
            <div className="col-12 col-lg-6">
              <h2 className="display-5 fw-normal">Chính sách</h2>
              {/*<ul className="list-inline mt-3">*/}
              {/*<li>*/}
              {/*    <a className="button-circle button-circle-dark button-circle-hover-slide blog-slider-3-prev"*/}
              {/*       href="#">*/}
              {/*      <i className="bi bi-arrow-left"></i>*/}
              {/*      <i className="bi bi-arrow-left"></i>*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <a className="button-circle button-circle-dark button-circle-hover-slide blog-slider-3-next"*/}
              {/*       href="#">*/}
              {/*      <i className="bi bi-arrow-right"></i>*/}
              {/*      <i className="bi bi-arrow-right"></i>*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>
            {/*<div className="col-12 col-lg-6">*/}
            {/*  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore*/}
            {/*    et dolore magna aliqua</p>*/}
            {/*  <div className="mt-3">*/}
            {/*    <a className="button-fancy-gradient-1" href="#">*/}
            {/*      View More Posts*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <div className="mt-4 mt-lg-5">
            <Swiper
              modules={[Pagination]}
              spaceBetween={40} // Khoảng cách giữa các slide
              slidesPerView={3} // Số slide hiển thị cùng lúc
            >
              <SwiperSlide>
                <div className="float-card">
                  <div className="img-link-box">
                    <a href="blog/blog-single.html">
                      <img
                        src={require("../../../assets/images/blog-slide-1.jpg")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="float-card-text bg-white border-radius">
                    <ul className="list-inline-dash mb-2 font-small">
                      <li>Mar 4, 2024</li>
                      <li>Work</li>
                    </ul>
                    <h4 className="mb-0">
                      <a
                        className="link-hover-line"
                        href="blog/blog-single.html"
                      >
                        Best work environment
                      </a>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="float-card">
                  <div className="img-link-box">
                    <a href="blog/blog-single.html">
                      <img
                        src={require("../../../assets/images/blog-slide-2.jpg")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="float-card-text bg-white border-radius">
                    <ul className="list-inline-dash mb-2 font-small">
                      <li>Mar 4, 2024</li>
                      <li>Lifestyle</li>
                    </ul>
                    <h4 className="mb-0">
                      <a
                        className="link-hover-line"
                        href="blog/blog-single.html"
                      >
                        Keeping things simple
                      </a>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="float-card">
                  <div className="img-link-box">
                    <a href="blog/blog-single.html">
                      <img
                        src={require("../../../assets/images/blog-slide-3.jpg")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="float-card-text bg-white border-radius">
                    <ul className="list-inline-dash mb-2 font-small">
                      <li>Mar 4, 2024</li>
                      <li>Tips</li>
                    </ul>
                    <h4 className="mb-0">
                      <a
                        className="link-hover-line"
                        href="blog/blog-single.html"
                      >
                        Tips for a healthy lifestyle
                      </a>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="float-card">
                  <div className="img-link-box">
                    <a href="blog/blog-single.html">
                      <img
                        src={require("../../../assets/images/blog-slide-4.jpg")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="float-card-text bg-white border-radius">
                    <ul className="list-inline-dash mb-2 font-small">
                      <li>Mar 4, 2024</li>
                      <li>Lifestyle</li>
                    </ul>
                    <h4 className="mb-0">
                      <a
                        className="link-hover-line"
                        href="blog/blog-single.html"
                      >
                        Work-Life Balance
                      </a>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="section-spacing pt-5">
        <div className="container">
          <div className="row g-4 g-xl-5 align-items-center">
            <div className="col-12 col-lg-6">
              <h2 className="display-5 fw-normal">Tin tức</h2>
              {/*<ul className="list-inline mt-3">*/}
              {/*<li>*/}
              {/*    <a className="button-circle button-circle-dark button-circle-hover-slide blog-slider-3-prev"*/}
              {/*       href="#">*/}
              {/*      <i className="bi bi-arrow-left"></i>*/}
              {/*      <i className="bi bi-arrow-left"></i>*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <a className="button-circle button-circle-dark button-circle-hover-slide blog-slider-3-next"*/}
              {/*       href="#">*/}
              {/*      <i className="bi bi-arrow-right"></i>*/}
              {/*      <i className="bi bi-arrow-right"></i>*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>
            {/*<div className="col-12 col-lg-6">*/}
            {/*  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore*/}
            {/*    et dolore magna aliqua</p>*/}
            {/*  <div className="mt-3">*/}
            {/*    <a className="button-fancy-gradient-1" href="#">*/}
            {/*      View More Posts*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <div className="mt-4 mt-lg-5">
            <Swiper
              modules={[Pagination]}
              spaceBetween={40} // Khoảng cách giữa các slide
              slidesPerView={3} // Số slide hiển thị cùng lúc
            >
              <SwiperSlide>
                <div className="float-card">
                  <div className="img-link-box">
                    <a href="blog/blog-single.html">
                      <img
                        src={require("../../../assets/images/blog-slide-1.jpg")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="float-card-text bg-white border-radius">
                    <ul className="list-inline-dash mb-2 font-small">
                      <li>Mar 4, 2024</li>
                      <li>Work</li>
                    </ul>
                    <h4 className="mb-0">
                      <a
                        className="link-hover-line"
                        href="blog/blog-single.html"
                      >
                        Best work environment
                      </a>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="float-card">
                  <div className="img-link-box">
                    <a href="blog/blog-single.html">
                      <img
                        src={require("../../../assets/images/blog-slide-2.jpg")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="float-card-text bg-white border-radius">
                    <ul className="list-inline-dash mb-2 font-small">
                      <li>Mar 4, 2024</li>
                      <li>Lifestyle</li>
                    </ul>
                    <h4 className="mb-0">
                      <a
                        className="link-hover-line"
                        href="blog/blog-single.html"
                      >
                        Keeping things simple
                      </a>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="float-card">
                  <div className="img-link-box">
                    <a href="blog/blog-single.html">
                      <img
                        src={require("../../../assets/images/blog-slide-3.jpg")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="float-card-text bg-white border-radius">
                    <ul className="list-inline-dash mb-2 font-small">
                      <li>Mar 4, 2024</li>
                      <li>Tips</li>
                    </ul>
                    <h4 className="mb-0">
                      <a
                        className="link-hover-line"
                        href="blog/blog-single.html"
                      >
                        Tips for a healthy lifestyle
                      </a>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="float-card">
                  <div className="img-link-box">
                    <a href="blog/blog-single.html">
                      <img
                        src={require("../../../assets/images/blog-slide-4.jpg")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="float-card-text bg-white border-radius">
                    <ul className="list-inline-dash mb-2 font-small">
                      <li>Mar 4, 2024</li>
                      <li>Lifestyle</li>
                    </ul>
                    <h4 className="mb-0">
                      <a
                        className="link-hover-line"
                        href="blog/blog-single.html"
                      >
                        Work-Life Balance
                      </a>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}
